// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audioPulse {
  display: flex;
  width: 24px;
  justify-content: space-evenly;
  align-items: center;
  transition: all 0.5s;
  height: 4px;
  transition: opacity 0.333s;
}
.audioPulse > div {
  background-color: var(--Neutral-30);
  width: 4px;
  min-height: 4px;
  border-radius: 1000px;
  transition: height 0.1s;
}
.audioPulse.hover > div {
  animation: hover 1.4s infinite alternate ease-in-out;
}
.audioPulse.active {
  opacity: 1;
}
.audioPulse.active > div {
  background-color: var(--Neutral-5);
}

@keyframes hover {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-3.5px);
  }
}
@keyframes pulse {
  from {
    scale: 1 1;
  }
  to {
    scale: 1.2 1.2;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/audio-pulse/audio-pulse.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,6BAAA;EACA,mBAAA;EACA,oBAAA;EAeA,WAAA;EACA,0BAAA;AAbF;AADE;EACE,mCAAA;EAEA,UAAA;EACA,eAAA;EACA,qBAAA;EACA,uBAAA;AAGJ;AAAE;EACE,oDAAA;AAEJ;AAIE;EACE,UAAA;AAFJ;AAII;EACE,kCAAA;AAFN;;AAOA;EACE;IACE,wBAAA;EAJF;EAOA;IACE,6BAAA;EALF;AACF;AAQA;EACE;IACE,UAAA;EANF;EASA;IACE,cAAA;EAPF;AACF","sourcesContent":[".audioPulse {\n  display: flex;\n  width: 24px;\n  justify-content: space-evenly;\n  align-items: center;\n  transition: all 0.5s;\n\n  & > div {\n    background-color: var(--Neutral-30);\n    border-radius: 1000px;\n    width: 4px;\n    min-height: 4px;\n    border-radius: 1000px;\n    transition: height 0.1s;\n  }\n\n  &.hover > div {\n    animation: hover 1.4s infinite alternate ease-in-out;\n  }\n\n  height: 4px;\n  transition: opacity 0.333s;\n\n  &.active {\n    opacity: 1;\n\n    & > div {\n      background-color: var(--Neutral-5);\n    }\n  }\n}\n\n@keyframes hover {\n  from {\n    transform: translateY(0);\n  }\n\n  to {\n    transform: translateY(-3.5px);\n  }\n}\n\n@keyframes pulse {\n  from {\n    scale: 1 1;\n  }\n\n  to {\n    scale: 1.2 1.2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
